import Address from "./Address";
import { Accordion, AccordionTab } from "primereact/accordion";

const stateAbbrevToFull_ = {
  AL: "Alabama",
};

function State(props) {
  const { state_locations } = props;
  const Addresses = Object.keys(state_locations)
    .sort((a, b) => {
      var nameA = state_locations[a].address.city.toUpperCase(); // ignore upper and lowercase
      var nameB = state_locations[b].address.city.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    .map((location, index) => {
      let location_info = state_locations[location];
      let { line1, line2, city, state, country, zip } = location_info[
        "address"
      ];
      let { manufacturer } = location_info;
      console.log(location_info)
      if (manufacturer) {
        var manufacturers = manufacturer
          .map((value, index) => value["name"])
          .join();
      } else {
        var manufacturers = "";
      }

      let { appointmentAvailability } = location_info;
      const AppointmentList = appointmentAvailability.map((value, index) => {
        let { slots } = value;
        const Slots = slots.map((value, index) => {
          return (
            <li>
              {" "}
              <a href="https://www.walgreens.com/findcare/vaccination/covid-19">
                {" "}
                {value}
              </a>{" "}
            </li>
          );
        });
        return (
          <AccordionTab header={`${value["date"]} | Slots: ${slots.length}`}>
            <ul>{Slots}</ul>
          </AccordionTab>
        );
      });

      return (
        <div>
          <Address
            line1={line1}
            line2={line2}
            city={city}
            state={state}
            country={country}
            zip={zip}
          />
          <p>
          Vaccine available: {manufacturers}
          </p>

          <Accordion>{AppointmentList}</Accordion>
        </div>
      );
    });
  return (
    <div>
      <h2> {props.state}</h2>
      <Accordion>
        <AccordionTab
          header={`Total Stores: ${props.addresses_count} | Total Slots: ${props.slots_count}`}
        >
          {Addresses}
        </AccordionTab>
      </Accordion>
    </div>
  );
}

export default State;
