function Address(props) {
  const GoogleMapsUrl = `https://maps.google.com/?q=${props.line1} ${props.line2} ${props.city}, ${props.state} ${props.zip}`;
  return (
    <div>
      <a href={GoogleMapsUrl}>
        <h4>
          <span> {props.line1}</span>
          <span> {props.line2}</span>
          <br />
          <span> {props.city},</span>
          <span> {props.state}</span>
          <span> {props.zip}</span>
        </h4>
      </a>
    </div>
  );
}

export default Address;
