import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.css";
import State from "./State";
import app_data from "./appointment.json";
import logo from "./logogood.png";

function App() {
  const appointment_data = app_data["appointment_data"];

  const States = Object.keys(appointment_data)
    .sort()
    .map((state, index) => {
      let totalSlots = Object.keys(appointment_data[state]).reduce(
        (accumulator, currentValue) => {
          let locationSlots = appointment_data[state][currentValue][
            "appointmentAvailability"
          ].reduce((acc, curValue) => {
            return acc + curValue["slots"].length;
          }, 0);
          console.log("SLOTS", locationSlots);

          return accumulator + locationSlots;
        },
        0
      );
      return (
        <State
          state={state}
          addresses_count={Object.keys(appointment_data[state]).length}
          slots_count={totalSlots}
          index={index}
          state_locations={appointment_data[state]}
        />
      );
    });
  return (
    <div className="container">
      <div className="p-grid">
        <div className="p-lg-1 p-lg-offset-3 p-md-10 p-md-offset-2 p-sm-11">
          <img
            alt="logo"
            style={{ width: "10em" }}
            src={logo}
          ></img>{" "}
        </div>{" "}
        <div
          className="p-lg-7 p-lg-offset-1 p-md-9 p-md-offset-2 p-sm-10"
          style={{ fontSize: "3em" }}
        >
          Walgreens Vaccine <br />
          Availability Monitor{" "}
        </div>{" "}
      </div>{" "}
      <div className="p-grid">
        <div
          className="p-lg-9 p-lg-offset-3 p-md-9 p-md-offset-2 p-sm-10"
          // style={{ width: "30rem", marginBottom: "5x", fontSize: "x-large" }}
        >
          <strong> Last Updated: {app_data["updated"]} </strong>{" "}
        </div>{" "}
      </div>{" "}
      <div className="p-grid">
        <div
          className="p-lg-5 p-lg-offset-3  p-md-offset-2 p-md-6 p-sm-10"
          // style={{ width: "30rem", marginBottom: "5x", fontSize: "x-large" }}
        >
          <hr />
          <p>
            During a period the when there was shortage of Covid-19 vaccines, this
            website served as a tool for finding available vaccines at Walgreens by 
            displaying all available Walgreens vaccines in the United States.{" "}
          </p>{" "}

          <p>
            However, now vaccines are widely available, you can find your closest vaccine at Walgreens 
            by simply visiting the{" "}
            <a href="https://www.walgreens.com/findcare/vaccination/covid-19">
              official vaccine website{" "}
            </a>.
          </p>{" "}
          <hr />
        </div>

        <div className="p-lg-4 pg-md-4 p-sm-2"> </div>{" "}

      </div>{" "}
      <div className="p-grid">
        <div className="p-lg-5 p-lg-offset-3 p-md-6 p-md-offset-2 p-sm-10"> {States} </div>{" "}
        <div className="p-lg-4 p-md-4 p-sm-2"> </div>{" "}
      </div>{" "}
      <div className="p-grid">
        <div className="p-lg-3 p-md-3 p-sm-1"> </div>{" "}
        <div
          className=" p-lg-4 p-md-4  p-sm-10"
          style={{ width: "30rem", marginBottom: "20px", marginTop: "20px" }}
        >
          <i> Created by <a href="https://twitter.com/jbialer">Jake Bialer</a> </i>{" "}
        </div>{" "}
        <div className=" p-lg-4 p-md-4 p-sm-1"> </div>{" "}
      </div>{" "}
    </div>
  );
}

export default App;
